const Helper = (function() {
  function isEmptyObject(obj) {
    if (! isNull(obj.className)) { return false }

    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }

  function isNull(obj) {
    return obj == null || obj == undefined || obj == 'undefined'
  }

  function isEmpty(obj) {
    return isNull(obj) || obj == '' || obj.length == 0 || obj == {}
  }

  function hasDataAttribute(element, attribute) {
    if (isNull(element.dataset[attribute])) { return false; }

    return element.dataset[attribute] == '' || element.dataset[attribute].length > 0;
  }

  function guard(entity, _function){
    if (isNull(entity)) { return; }
    _function();
  }

  // on('.parent', '.child', 'click', function(){})
  function on(parentSelector, childSelector, event, callback) {
    const _listener = function(event) {
      if (event.target.matches(childSelector)) {
        callback(event.target, event)
      }
    }

    document.querySelector(parentSelector).removeEventListener(event, _listener);
    document.querySelector(parentSelector).addEventListener(event, _listener);
  }

  function onDocument(childSelector, event, callback) {
    function handler(event) {
      if (event.target.matches(childSelector)) {
        callback(event.target, event)
      }
    }

    document.removeEventListener(event, handler)
    document.addEventListener(event, handler)
  }

  let myCallback ;
  let myChildSelector;

  function createOnEventHandler(childSelector, callback){
    const domListener = function(event) {
      if (event.target.matches(childSelector)) {
        callback(event.target, event)
      }
    }
    return domListener;
  }

  function addEventListener(selector, event, callback) {
    document.querySelector(selector).removeEventListener(event, callback);
    document.querySelector(selector).addEventListener(event, callback);
  }

  function addEventListenerAll(selector, event, callback) {
    const elements = Array.from(document.querySelectorAll(selector));
    elements.forEach(element => {
      element.removeEventListener(event, callback);
      element.addEventListener(event, callback);
    })
  }

  function dataSelector(selector, all= false, element = null, ) {
    const parent = element == null ? document : element
    if (all) {
      return parent.querySelectorAll('[data-' + selector + ']')
    } else {
      return parent.querySelector('[data-' + selector + ']')
    }
  }

  function dispatchEvent(eventName, data = {}, source = null, target = null) {
    const event = new CustomEvent('custom::' + eventName, {
      detail: {
        source: source,
        target: target,
        payload: data
      }});
    document.dispatchEvent(event);
  }

  function handleCustomEvent(eventName, callback) {
    document.removeEventListener('custom::' + eventName, callback)
    document.addEventListener('custom::' + eventName, callback)
  }

  function isElementInViewport (el) {
    const rect = el.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
      rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
  }

  function icon(klass) {
    if (klass == 'close') {
      return  '<svg class="bi bi-x" viewBox="0 0 16 16" fill="currentColor" version="1.1" width="1em" height="1em" aria-hidden="true"><path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path></svg>'
    } else if (klass == 'help') {
      return '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/></svg>'
    }

    return '';
  }

  function iconElement(klass){
    const element = document.createElement('div');
    element.innerHTML = icon(klass);
    return element.firstChild;
  }

  function appendElement(target, elementName) {
    const element = document.createElement(elementName);
    target.appendChild(element);
    return element;
  }

  function createElement(kind, classes, text = null, parent = null, dataset = null, attributes = null) {
    const element = document.createElement(kind);
    element.className = classes

    if (attributes) {
      for (const key of Object.keys(attributes)) {
        element.setAttribute(key, attributes[key])
      }
    }

    if (dataset) {
      for (const key of Object.keys(dataset)) {
        element.dataset[key] = dataset[key]
      }
    }

    if (text) {
      element.innerText = text;
    }

    if (parent){
      parent.appendChild(element)
    }

    return element;
  }

  return {
    isEmptyObject: isEmptyObject,
    isNull: isNull,
    isNotNull: !isNull,
    isEmpty: isEmpty,
    hasDataAttribute: hasDataAttribute,
    guard: guard,
    on: on,
    onDocument: onDocument,
    addEventListener: addEventListener,
    addEventListenerAll: addEventListenerAll,
    dataSelector: dataSelector,
    dispatchEvent: dispatchEvent,
    handleCustomEvent: handleCustomEvent,
    icon: icon,
    iconElement: iconElement,
    appendElement: appendElement,
    createElement: createElement,
    isElementInViewport: isElementInViewport
  }
})();

export default Helper
